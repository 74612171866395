import React from "react";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import GitHubIcon from '@mui/icons-material/GitHub';
import "../styles/Footer.css";

function Footer() {
  return (
    <div className="footer">
      <div className="socialMedia">
        <a href="https://www.linkedin.com/in/adam-krawchuk/"><LinkedInIcon /></a>
        <a href="mailto:adamkrawchuk@gmail.com"><EmailIcon /></a>
        <a href="https://github.com/wizard-adamkay/"><GitHubIcon /></a>
      </div>
      <p> &copy; 2024 adamkrawchuk.com</p>
    </div>
  );
}

export default Footer;
