import React from "react";
import "../../styles/ProjectDisplay.css";

const IPSDescription = () => {
return (
    <div className="desc">
        <h2>Summary</h2>
        <p>The Network Analysis and Intrusion Prevention System (IPS) Application is a network protocol analyzer that integrates intrusion detection system (IDS) and IPS features into a user-friendly graphical interface. Unlike traditional IDS/IPS solutions, this application enhances packet capture analysis with graphing, filtering, and summarization capabilities. Leveraging Snort, it highlights malicious packets, enabling users to swiftly identify and respond to threats. Users can terminate ongoing connections or block offending IP addresses directly from the interface. With significant quality-of-life improvements over industry-standard tools like Wireshark, the application offers enhanced filtering, querying from previous results, and more ergonomic navigation for efficient packet capture analysis.</p>
        <h2>Features</h2>
        <ul>
            <li>Packet capture analysis with graphing and filtering</li>
            <li>Enhanced filtering and querying from previous results</li>
            <li>Malicious packet detection with Snort integration</li>
            <li>Connection termination and IP address blocking</li>
        </ul>
    </div>
);
};
export default IPSDescription