import React from "react";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import GitHubIcon from '@mui/icons-material/GitHub';
import "../styles/Home.css";
import BcitLogo from "../assets/BCIT.png";

function Home() {
  return (
    <div className="home">
      <div className="about">
        <h2>Hi, I'm Adam Krawchuk</h2>
        <div className="prompt">
          <p>A software developer with a passion for learning and creating.</p>
          <a href="https://www.linkedin.com/in/adam-krawchuk/"><LinkedInIcon /></a>
          <a href="mailto:adamkrawchuk@gmail.com"><EmailIcon /></a>
          <a href="https://github.com/wizard-adamkay"><GitHubIcon /></a>
        </div>
      </div>
      <div className="skills">
        <h1>Skills</h1>
        <ol className="list">
          <li className="item">
            <h2> Languages</h2>
            <span>
            Python, Java, C++, C#, SQL, Bash, HTML/CSS, JavaScript, TypeScript
            </span>
          </li>
          <li className="item">
            <h2>Frameworks and Libraries</h2>
            <span>
            .NET, Node.js, React, Cypress, Jest
            </span>
          </li>
          <li className="item">
            <h2>Tools and Platforms</h2>
            <span>
            Docker, Jenkins, Postman, Azure, Linux, Git, Jira
            </span>
          </li>
          <li className="item">
            <h2>Cybersecurity Tools</h2>
            <span>
            Wireshark, Metasploit, Splunk, Nmap, Snort, Maltego
            </span>
          </li>
          <li className="item">
            <h2>Methodologies</h2>
            <span>
            Scrum, Agile
            </span>
          </li>
        </ol>
      </div>
      <div className="education">
        <h1>Education</h1>
        <ol className="list">
          <li className="item">
            <img src={BcitLogo} alt="BCIT Logo" className="bcit-logo" />
            <div className="details">
              <h2>British Columbia Institute of Technology</h2>
              <span className="date-mobile">2019 - 2023</span>
              <span>Bachelor of Technology in Computer Systems</span>
              <span>Computer Systems Technology</span>
              <span>Network Security Applications Development</span>
              <span>GPA: 82%</span>
            </div>
            <div className="date">
              <h2>2019 - 2023</h2>
            </div>
          </li>
        </ol>
      </div>
    </div>
  );
}

export default Home;
