import React from "react";
import { useParams } from "react-router-dom";
import { ProjectList } from "../helpers/ProjectList";
import GitHubIcon from '@mui/icons-material/GitHub';
import "../styles/ProjectDisplay.css";
import ImageCarousel from "../components/ImageCarousel";

function ProjectDisplay() {
  const { id } = useParams();
  const project = ProjectList[id];
  return (
    <div className="project">
      <h1> {project.name}</h1>
      <ImageCarousel media={project.media}></ImageCarousel>
      <p className="tech">
        <b>Technologies Used:</b> {project.skills}
      </p>
      {project.description}
      <div>{project.link ? <a href={project.link}><GitHubIcon /></a> : null} {project.link2 ? <a href={project.link2}><GitHubIcon /></a> : null}</div>
      
    </div>
  );
}

export default ProjectDisplay;
