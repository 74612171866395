import React from "react";
import "../../styles/ProjectDisplay.css";

const JOBSDescription = () => {
return (
    <div className="desc">
        <h2>Summary</h2>
        <p>The Job Postings Aggregator is a tool designed to streamline the job search process by consolidating job listings from multiple websites into a single application. This project aims to reduce the time and effort required to search for job opportunities. Using a combination of Scrapy and Python Requests, the tool efficiently scrapes job postings from various job boards and websites based on user-defined search queries.</p>
        <p>The application leverages Python for its core functionality, ensuring robust data handling and manipulation. A user-friendly interface built with Tkinter allows users to interact with the tool effortlessly, providing options to search for jobs, view results, and manage their job search effectively. Additionally, the application uses SQLite for storing job postings, enabling users to save and revisit job listings at their convenience.</p>
        <p>By integrating results from multiple job sites, the Job Postings Scraper offers a comprehensive overview of available opportunities, helping users make informed decisions about their job search. Overall, this tool provides a practical and efficient solution for job seekers looking to optimize their search process and keep track of job opportunities in one place.</p>
        <h2>Features</h2>
        <ul>
            <li>Consolidate job listings from multiple websites</li>
            <li>Search for jobs based on user-defined queries</li>
            <li>View and manage job search results</li>
            <li>Store job postings in SQLite database</li>
        </ul>
    </div>
);
};
export default JOBSDescription