import React from "react";
import "../../styles/ProjectDisplay.css";

const SIMUDescription = () => {
return (
    <div className="desc">
        <h2>Summary</h2>
        <p>The TCP Packet Loss Simulator is a tool designed to simulate packet loss in a network, providing options to delay or drop packets entirely while graphing the results in real-time. The application operates by initiating the transmission of packets from a transmitter to a relay. The relay, under user-defined specifications, can introduce delays or drop packets entirely before forwarding them to the receiver. The receiver then sends an acknowledgment back to the relay, which in turn relays this information back to the transmitter.</p>
        <p>The transmitter dynamically adjusts its window size and retransmits packets based on the observed occurrences of dropped or delayed packets. These adjustments are reflected in the graphical user interface of the relay, providing real-time visualization of the packet transmission process. The application utilizes four distinct ports: two for acknowledgments and two for packets containing data. Technologies used in this project include Python for the core logic, Matplotlib for real-time graphing, and Numpy for numerical operations. Overall, the TCP Packet Loss Simulator offers a practical and insightful way to study the impact of packet loss and delays on network performance, making it a valuable tool for network analysis and optimization.</p>
        <h2>Features</h2>
        <ul>
            <li>Simulate packet loss and delays</li>
            <li>Graph results in real-time</li>
            <li>Dynamic adjustment of transmitter window size</li>
        </ul>
    </div>
);
};
export default SIMUDescription