import React from "react";
import "../../styles/ProjectDisplay.css";

const POCBOTDescription = () => {
return (
    <div className="desc">
        <h2>Summary</h2>
        <p>This application is a bot designed for Legends of Runeterra. It plays the game in offline mode by recognizing in-game images and performing actions through simulated clicks. The bot operates within predefined areas using normalized random clicks to mimic human behavior, ensuring it remains undetected during extended use. Its image recognition capabilities and strategic interactions make it a reliable and effective tool for automating gameplay.</p>
	    <h2>Features</h2>
        <ul>
            <li>Recognizes in-game images</li>
            <li>Performs actions through simulated clicks</li>
            <li>Operates within predefined areas using normalized random clicks</li>
            <li>Remains undetected during extended use</li>
        </ul>
    </div>
);
};
export default POCBOTDescription