import React from "react";
import "../../styles/ProjectDisplay.css";

const PROJMANAGEDescription = () => {
return (
    <div className="desc">
        <h2>Summary</h2>
        <p>The Project Management Website is a web application for improving the efficiency of project management activities for teams. This platform provides tools for planning projects, tracking time, and managing employee information. The project planning features enable users to create, organize, and oversee project plans, set goals, timelines, and milestones, and assign tasks to team members. The timecard keeping functionality allows employees to log their work hours accurately, facilitating productivity monitoring and ensuring precise time tracking. Additionally, the application includes robust employee management capabilities, offering a user-friendly interface for performing CRUD operations on employee records, thereby aiding in the organization and retrieval of essential employee data. The backend of the application is built using Java, while MySQL is employed to manage the database, ensuring scalability and reliability. The frontend leverages React for efficient data management operations. To maintain high-quality standards, the project incorporates automated testing tools such as Cypress for end-to-end testing, Postman for API validation, JUnit for unit testing, and Jenkins for continuous integration and deployment. Kubernetes is used for container orchestration, allowing the application to scale effectively to meet varying user demands.</p>
        <h2>Features</h2>
        <ul>
            <li>Creation and assignment of workpackages</li>
            <li>Estimation tools</li>
            <li>Project proposal and approval functionality</li>
            <li>Timecard keeping functionality</li>
            <li>Employee management capabilities</li>
        </ul>
    </div>
);
};
export default PROJMANAGEDescription