import React from "react";
import "../../styles/ProjectDisplay.css";

const JOBALERTDescription = () => {
return (
    <div className="desc">
        <h2>Summary</h2>
        <p>The Job Alert application checks career pages of specified companies for job postings within a chosen city. It compares the found postings against an SQLite database to identify new opportunities and sends notifications of these new postings via Gmail to a specified address. Additionally, it keeps the database current by removing entries for postings that are no longer listed on the career pages. This automation simplifies job searching and ensures users stay informed of the latest job openings.</p>
        <h2>Features</h2>
        <ul>
            <li>Scrapes career pages of specified companies</li>
            <li>City specific job searching</li>
            <li>Gmail updates for quick notification</li>
            <li>Reduces the effort required in job searching</li>
        </ul>
    </div>
);
};
export default JOBALERTDescription