import IPSDescription from '../components/descriptions/IPS';
import JOBALERTDescription from '../components/descriptions/JOBALERT';
import JOBSDescription from '../components/descriptions/JOBS';
import POCBOTDescription from '../components/descriptions/POCBOT';
import PROJMANAGEDescription from '../components/descriptions/PROJMANAGE';
import ROOTDescription from '../components/descriptions/ROOT';
import SIMUDescription from '../components/descriptions/SIMU';
import STEGDescription from '../components/descriptions/STEG';

const importAll = (r) => {
  return r.keys().map(r);
};

const IPSImages = importAll(require.context('../assets/IPS', false, /\.(png|jpe?g|svg|gif)$/));
const POCBOTImages = importAll(require.context('../assets/POCBOT', false, /\.(png|jpe?g|svg|gif)$/));
const STEGImages = importAll(require.context('../assets/STEG', false, /\.(png|jpe?g|svg|gif)$/));
const PROJMANAGEImages = importAll(require.context('../assets/PROJMANAGE', false, /\.(png|jpe?g|svg|gif)$/));
const JOBSImages = importAll(require.context('../assets/JOBS', false, /\.(png|jpe?g|svg|gif)$/));
const SIMUImages = importAll(require.context('../assets/SIMU', false, /\.(png|jpe?g|svg|gif)$/));
const ROOTImages = importAll(require.context('../assets/ROOT', false, /\.(png|jpe?g|svg|gif)$/));
const JOBALERTImages = importAll(require.context('../assets/JOBALERT', false, /\.(png|jpe?g|svg|gif)$/));
const [IPS1, IPS2, IPS3, IPS4, IPS5, IPS6] = IPSImages;
const [POCBOT1, POCBOT2, POCBOT3, POCBOT4, POCBOT5] = POCBOTImages;
const [STEG1, STEG2, STEG3] = STEGImages;
const [PROJMANAGE1, PROJMANAGE2, PROJMANAGE3, PROJMANAGE4, PROJMANAGE5] = PROJMANAGEImages;
const [JOBS1, JOBS2, JOBS3] = JOBSImages
const [SIMU1, SIMU2, SIMU3, SIMU4, SIMU5] = SIMUImages;
const [ROOT1, ROOT2, ROOT3, ROOT4] = ROOTImages;
const [JOBALERT1, JOBALERT2, JOBALERT3] = JOBALERTImages;
export const ProjectList = [
  {
    name: "Network Analysis and IPS Application",
    image: IPS1,
    link: "https://github.com/wizard-adamkay/Network_Protocol_Analyzer",
    media: [
      {type: 'image', src: IPS1},
      {type: 'image', src: IPS2},
      {type: 'image', src: IPS3},
      {type: 'image', src: IPS4},
      {type: 'image', src: IPS5},
      {type: 'image', src: IPS6}
    ],
    skills: "Python, Jenkins, Scapy, Snort, Iptables, Tkinter, Matplotlib",
    description: <IPSDescription />,
  },
  {
    name: "Game Automation Application",
    image: POCBOT1,
    media: [{type: 'image', src: POCBOT1},
    {type: 'image', src: POCBOT2},
    {type: 'image', src: POCBOT3},
    {type: 'image', src: POCBOT4},
    {type: 'image', src: POCBOT5}
  ],
    skills: "Python, Matplotlib, Numpy, opencv-python, pyautogui, pillow",
    description: <POCBOTDescription />,
  },
  {
    name: "Steganography Tool",
    image: STEG1,
    link: "https://github.com/wizard-adamkay/Steganography_Tool",
    media: [
    {type: 'image', src: STEG1},
    {type: 'image', src: STEG2},
    {type: 'image', src: STEG3}
  ],
    skills: "Python, Cryptography, Pillow",
    description: <STEGDescription />,
  },
  {
    name: "Project Management Website",
    image: PROJMANAGE1,
    link: "https://gitlab.infoteach.ca/yverma/yojana-backend",
    link2: "https://gitlab.infoteach.ca/yverma/yojana-client",
    media: [
    {type: 'image', src: PROJMANAGE1},
    {type: 'image', src: PROJMANAGE2},
    {type: 'image', src: PROJMANAGE3},
    {type: 'image', src: PROJMANAGE4},
    {type: 'image', src: PROJMANAGE5}
  ],
    skills: "Java, Junit, Postman, JPA, MySQL, Kubernetes, Cypress, Jenkins",
    description: <PROJMANAGEDescription />,
  },
  {
    name: "Job Posting Aggregator",
    image: JOBS1,
    media: [
    {type: 'image', src: JOBS1},
    {type: 'image', src: JOBS2},
    {type: 'image', src: JOBS3}
  ],
    skills: "Python, Scrapy, Python Requests, Tkinter, SQLite",
    description: <JOBSDescription />,
  },
  {
    name: "TCP Packet Loss Simulator",
    image: SIMU1,
    link: "https://github.com/wizard-adamkay/TCP-Packet-Loss-Simulator",
    media: [
    {type: 'image', src: SIMU1},
    {type: 'image', src: SIMU2},
    {type: 'image', src: SIMU3},
    {type: 'image', src: SIMU4},
    {type: 'image', src: SIMU5}
  ],
    skills: "Python, Matplotlib, Numpy",
    description: <SIMUDescription />,
  },
  {
    name: "Rootkit",
    image: ROOT1,
    link: "https://github.com/wizard-adamkay/rootkit",
    media: [
    {type: 'image', src: ROOT1},
    {type: 'image', src: ROOT2},
    {type: 'image', src: ROOT3},
    {type: 'image', src: ROOT4}
  ],
    skills: "Python, Scapy, Cryptography",
    description: <ROOTDescription />,
  },
  {
    name: "Custom Job Alert",
    image: JOBALERT1,
    link: "https://github.com/wizard-adamkay/Job-Alert",
    media: [
    {type: 'image', src: JOBALERT1},
    {type: 'image', src: JOBALERT2},
    {type: 'image', src: JOBALERT3}
  ],
    skills: "Python, SQLite, lxml",
    description: <JOBALERTDescription />,
  },
];