import React from "react";
import "../../styles/ProjectDisplay.css";

const STEGDescription = () => {
return (
    <div className="desc">
        <h2>Summary</h2>
        <p>The Steganography Tool is a sophisticated application designed to enable users to securely and covertly embed text or files within images. By manipulating the least significant bits of each pixel's RGBA values, the tool provides a seamless and effective method for information concealment. Users can easily encode a file within an image or decode hidden information from an image using simple command-line instructions.</p>
        <p>To encode a file, users run a command specifying the file to be hidden and the target image that will store the hidden file. Similarly, decoding involves specifying the image with the hidden file and a key to retrieve the concealed information. The tool is built using Python, leveraging its flexibility and powerful data manipulation capabilities. It uses the Cryptography library to enhance security and the Pillow library to handle image processing. The steganographic algorithm operates by modifying the least significant bits of pixel values, ensuring the embedded data is inconspicuous.</p>
        <p>Additionally, users can fine-tune the process by adjusting the number of bits used in each RGBA color value, allowing for customization based on different concealment needs. The tool also employs a distribution mechanism that strategically disperses altered bits across the image, making the hidden information more difficult to detect. Overall, the Steganography Tool provides a robust and user-friendly solution for secure information embedding within images, ensuring both ease of use and high levels of security.</p>
        <h2>Features</h2>
        <ul>
            <li>Embed text or files within images</li>
            <li>Encrypts files before hiding them</li>
            <li>Fine-tune process by adjusting number of bits used in each RGBA color value</li>
            <li>Options for dispersing altered bits across the image</li>
        </ul>
    </div>
);
};
export default STEGDescription