import React from "react";
import "../../styles/ProjectDisplay.css";

const ROOTDescription = () => {
return (
    <div className="desc">
        <h2>Summary</h2>
        <p>The rootkit is a sophisticated tool designed for system intrusion and covert control. It features a keylogger that records keystrokes from the victim's machine, providing critical data to the attacker. All data and communication between the attacker and the victim are encrypted and hidden within HTML POST requests and responses, allowing for discrete communication.</p>
        <p>In addition to its keylogging capabilities, the rootkit allows for remote command execution, enabling the attacker to perform operations on the victim's machine as if they were physically present. It also supports file transfer operations, allowing the attacker to upload and download files from and to the victim. The rootkit can "watch" specified directories, recording any changes and automatically transferring this information to the attacker.</p>
        <p>To evade detection, the rootkit dynamically changes its process name to mimic the most duplicated process on the victim's machine, making it blend in with legitimate processes.</p>
        <h2>Features</h2>
        <ul>
            <li>Keylogger to record keystrokes</li>
            <li>Encrypted and hidden communication within HTML POST requests and responses</li>
            <li>Remote command execution</li>
            <li>File transfer operations</li>
            <li>Directory watching and change recording</li>
            <li>Process name mimicking for evasion</li>
        </ul>
    </div>
);
};
export default ROOTDescription