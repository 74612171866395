import './App.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Home from './pages/Home';
import Projects from './pages/Projects';
import Navbar from './components/Navbar';
import ProjectDisplay from "./pages/ProjectDisplay";
import Footer from "./components/Footer";
import ScrollToTop from './components/ScrollToTop';

function App() {
	return (
	<div className="App">
		<Router>
			<ScrollToTop>
				<Navbar/>
				<Routes>
					<Route path="/" element={<Home />}></Route>
					<Route path="/Projects" element={<Projects />}></Route>
					<Route path="/Projects/:id" element={<ProjectDisplay />} />
				</Routes>
			</ScrollToTop>
		</Router>
		<Footer />
	</div>
	);
}

export default App;