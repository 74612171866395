import React, { useState } from 'react';
import '../styles/ImageCarousel.css';

const ImageCarousel = ({ media  }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? media.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const goToNext = () => {
    const isLastSlide = currentIndex === media.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const goToSlide = (index) => {
    setCurrentIndex(index);
  };
  
  return (
    <div className="carousel-container">
      <button className="left-arrow" onClick={goToPrevious}>
        ❮
      </button>
      <div className="carousel-slide">
        {media[currentIndex].type === 'image' ? (
          <img src={media[currentIndex].src} alt={`Slide ${currentIndex}`} />
        ) : (
          <video controls>
            <source src={media[currentIndex].src} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}
      </div>
      <button className="right-arrow" onClick={goToNext}>
        ❯
      </button>
      <div className="carousel-indicators">
        {media.map((_, index) => (
          <span
            key={index}
            className={`carousel-indicator ${index === currentIndex ? 'active' : ''}`}
            onClick={() => goToSlide(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default ImageCarousel;